
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, SecurityContext } from "@angular/core";
import { filter, map, Subject } from "rxjs";
import { Subscription } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonUtil } from "../../talk/utils/common.util";
import { ConfigService } from "../../config.service";
import { OriginalMessage } from "../../talk/models/message.model";
import { AppService } from "../services/app.service";

interface Message {
  type: string;
  payload: any;
}

// let MessageCallback = (payload: any) => void;

@Injectable()
export class MessageService {
  constructor(private sanitizer: DomSanitizer, private configService: ConfigService, private appService: AppService) {
  }
  private handler = new Subject<Message>();

  broadcast(type: string, payload: any) {
    this.handler.next({ type, payload });
  }

  subscribe(type: string, callback: any): Subscription {
    return this.handler
      .pipe(filter(message => message.type === type)
      , map(message => message.payload))
      .subscribe(callback);
  }


  escapedAndSanitizedHtmlProperty(content: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.escapeHtml(content));
  }

  escapeHtml(unsafe: string): string {
    const escaped = CommonUtil.escapeHTMLString(unsafe);
    return escaped;
  }

  renderRepliedMessage(origMessage: OriginalMessage, references?: any[], skipEmoji?: boolean): { repliedMessage: string, originalMessageHTML: string, onlyText: boolean, repliedAudioUrl: string} {
    let onlyText: boolean = true;
    let repliedAudioUrl: string = "";

    if (!!origMessage.body) {
      origMessage.body = origMessage.body.replace(`<body xmlns="http://www.w3.org/1999/xhtml">`, "").replace("</body>", "");
    } else {
      // eslint-disable-next-line no-console
      console.error("[messageService][renderRepliedMessage] origMessage does not have a body!");
    }

    if (!!origMessage.replyMessage) {
      origMessage.replyMessage = origMessage.replyMessage.replace(`<body xmlns="http://www.w3.org/1999/xhtml">`, "").replace("</body>", "");
    } else {
      // eslint-disable-next-line no-console
      console.error("[messageService][renderRepliedMessage] origMessage does not have a replyMessage!");
    }


    let repliedMessage = CommonUtil.generateCachedBody(origMessage.replyMessage, "", skipEmoji);
    let body = CommonUtil.generateCachedBody(origMessage.body, "", skipEmoji);
    const text = /<body xmlns=\"http:\/\/www.w3.org\/1999\/xhtml\">(.*)<\/body>/ig.exec(origMessage.body);
    if (!!text && text[1]) {
      body = text[1];
    }

    let originalMessageHTML = body;

    // reply to location
    if (origMessage.location) {
      let location = JSON.parse(origMessage.location);
      let center = location.lat + "," + location.lng;
      let mapUrl = "https://maps.googleapis.com/maps/api/staticmap?center=" + center
        + "&zoom=13&size=600x300&maptype=roadmap&markers=color:red%7Clabel:"
        + location.label + "%7C" + center
        + "&key=" + this.configService.GOOGLE_STATIC_MAP_API_KEY;
      originalMessageHTML = "<img src='" + mapUrl + "' />";
      onlyText = false;

    // reply to attach
    } else if (origMessage.attachment) { // from the local or other vncTalk client
      let attachment = JSON.parse(origMessage.attachment);

      let url = attachment.url;
      if (!url && attachment["$t"]) {
        attachment = JSON.parse(attachment["$t"]);
        url = attachment.url;
      }

      if (!(url.startsWith("file:") || url.startsWith("blob:"))) {
        url = CommonUtil.translateHINFileURL(attachment.url);
      }

      if (attachment?.fileType && typeof attachment?.fileType !== "string") {
        attachment.fileType = attachment.fileType.toString();
      }

      const isAudio = CommonUtil.audioExtensions.indexOf(attachment.fileType.toLowerCase()) !== -1;
      const isImage = CommonUtil.imagesExtensions.indexOf(attachment.fileType.toLowerCase()) !== -1;
      const isSupportedVideo = CommonUtil.isSupportedVideo(attachment.fileType.toLowerCase());

      if (isImage) {
        let imageUrl = attachment.fileType.toLowerCase() === "gif" ? url : url.replace("share.php", "preview.php");
        if (!((imageUrl.startsWith("file:") || (imageUrl.startsWith("blob")))) ) {
          imageUrl = CommonUtil.translateHINFileURL(imageUrl);
        }
        originalMessageHTML = `<img src="${attachment.fileType.toLowerCase() === "gif" ? imageUrl : imageUrl.replace("share.php", "preview.php")}" />`;
      } else if (isAudio) {
        url = CommonUtil.translateHINFileURL(url);
        repliedAudioUrl = url; //Workaround replied audio url is using show wave in case of audio replied
        originalMessageHTML = "<audio id='music-" + origMessage.id + "' preload='true' controls controlsList='nodownload' onended='this.currentTime = 0;this.pause();'><source src='" + url + "'></audio>";
      } else if (isSupportedVideo) {

        const videoPreviewUrl = this.getAttachmentPreviewUrl(attachment.url);

        originalMessageHTML = `<img src=${videoPreviewUrl} />`;
      } else {
        originalMessageHTML = attachment.fileName + "<mat-icon class='material-icons'>attach_file</mat-icon>";
      }
      onlyText = false;

    // reply to link
    } else if (origMessage?.body && (origMessage.body.startsWith("http") || origMessage.body.startsWith("https"))) {
      originalMessageHTML = "<div class='preview-icon'><mat-icon class='material-icons'>link</mat-icon><div class='links'>"
      + originalMessageHTML + "</div></div>";
    }
    return {repliedMessage, originalMessageHTML, onlyText, repliedAudioUrl};
  }

  getAttachmentPreviewUrl(attachmentUrl) {
    return attachmentUrl.replace("/share.php/", "/preview.php/");
  }
}
